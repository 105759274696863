* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
}
body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  background-color: #f2ede6;
}
.app {
  max-width: 600px;
}
