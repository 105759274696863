body .react-tel-input {
  font-family: inherit;
}
body .react-tel-input .search-emoji {
  display: none;
}
body .react-tel-input .flag-dropdown {
  background-color: white;
  border: 0;
}

.flag-dropdown {
  background-color: var(--color-grey-100);
  border: none;
}
.flag-dropdown.open {
  background-color: var(--color-grey-100);
}
.flag-dropdown.open .selected-flag,
.flag-dropdown.open .selected-flag:hover,
.flag-dropdown.open .selected-flag:focus {
  background-color: var(--color-grey-100);
}

.flag-dropdown .selected-flag,
.selected-flag:hover,
.selected-flag:focus {
  background-color: var(--color-grey-100);
}

body .react-tel-input .country-list {
  border-radius: 4px;
}
body .react-tel-input .country-list .selected-flag {
  padding-left: 0;
}
body .react-tel-input .country-list .search {
  padding-left: 0;
  border-bottom: 1px solid var(--color-grey-500);
  margin-bottom: 8px;
}
body .react-tel-input .country-list .search-box {
  margin-left: 0;
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-grey-500);
  outline: none;
}
body .react-tel-input .country-list .search-box:focus {
  outline: 0;
  --tw-ring-color: #ffff;
}
body .react-tel-input .form-control {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  --tw-ring-color: #ffff;
  width: 100%;
  height: auto;
  font-size: inherit;
  font-family: inherit;
}
