

.text-h1 {
  font-size: 3rem;
}

.text-h2 {
  font-size: 2rem;
}

.text-h3 {
  font-size: 1.75rem;
}

.text-h4 {
  font-size: 1.2rem;
}

.text-h5 {
  font-size: 1rem;
}

.text-h6 {
  font-size: 1rem;
}

.text-big {
  font-size: 10rem;
}

.text-medium {
  font-size: .9rem;
}

.text-small {
  font-size: 0.75rem;
}
